<template>
  <div class="help" >
    <div class="container">
      <helpComponent />
    </div>
  </div>
</template>

<script>
const helpComponent = () => import('@/components/HelpComponent')

export default {
  name: 'Help',
  components: {
    helpComponent
  }
}
</script>
